
  .progress-wrapper {
    width: 12rem;
    overflow-x: hidden;
    vertical-align: middle;
    display: inline-block;
    line-height: 1;
    border-radius: 3px;
    position: relative;

    @media (prefers-color-scheme: dark) {
      background: rgba(255, 255, 255, 0.15);
    }

    @media (max-width: 640px) {
      max-width: 8rem;
    }

    .value {
      font-size: 90%;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      margin-top: 0.1rem;
      text-align: center;
      text-shadow: rgba(0, 0, 0, 0.4) 1px 1px 2px;

      @media (prefers-color-scheme: dark) {
        text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 3px;
      }
    }

    .progress {
      border-top: 2px solid rgba(255, 255, 255, 0.15);
      border-left: 2px solid rgba(255, 255, 255, 0.15);
      border-radius: 4px;
      height: 1rem;
    }
  }
