
@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  header, .no-print {
    display: none;
  }

  * {
    color: #000000;
  }
}
